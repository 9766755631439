/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-break': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 4.5V9h-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v7H2V2a2 2 0 012-2h5.5zM13 12h1v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2h1v2a1 1 0 001 1h8a1 1 0 001-1zM.5 10a.5.5 0 000 1h15a.5.5 0 000-1z"/>',
    },
});
